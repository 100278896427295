<template>
    <router-link class="notifications" :class="display" to="/messages">
        <div class="notification-bell" :class="{ 'bell-ringing' : notificationCount > 0}"></div>
        <div class="notification-count-container" v-if="notificationCount > 0" >
            <div class="notification-count">{{ notificationCount > 99 ? "99+" : notificationCount }}</div>
        </div>
    </router-link>
</template>

<script>
import { useMessageCenter } from '@/stores/messageCenter/messageCenter.js'
import { useSelectedPlan } from '@/stores/plans/selectedPlan.js'
import { useAccessRights } from '@/stores/accessRights/accessRights.js'
import { getMessageCountByMemberId } from '@/services/api/messaging/messages.js'
import { storeToRefs } from 'pinia'

export default {
    props: ['display'],
    data() {
        return {
            notificationCount: 0,
        }
    },
    setup() {
		const store = useAccessRights()
		const { accessRights, loaded } = storeToRefs(store)
		return { accessRights, loaded }
	},
    mounted() {
        if (this.loaded) {
			this.getMessageCount();
		}
    },
    methods: {
        async getMessageCount() {
			let messageCenter = useMessageCenter();
			let selectedPlan = useSelectedPlan();

			let accessibleMembers = this.accessRights.accessibleMembers.map((x) => {
				return x.memberId;
			});

			let memberString = accessibleMembers.join();

            getMessageCountByMemberId(selectedPlan.memberId, memberString)
                .then((res) => {
					let memberDataMap = new Map();
					res.data.forEach((item) => {
						if(!memberDataMap.has(item.memberId)) {
							memberDataMap.set(item.memberId, item)}
						}
					);
					let memberData = [...memberDataMap.values()]

					let totalCount = 0;
					memberData.map((item) => {
						totalCount += item.count;
					});
					messageCenter.updateMessageCenter({
						totalCount: totalCount,
					});
                });
		},
    },
    computed: {
		notificationCount() {
			let messageCenter = useMessageCenter();
			return messageCenter.totalCount; 
		}
	},
}
</script>

<style lang="scss" scoped>
.notifications {
	position: relative;
	margin-right: 30px;
    display: flex;
    align-items: center;
	width: 33px;
	cursor: pointer;
}
.notification-bell {
	background-image: url('@/assets/notification-bell.svg');
	background-position: 0 0;
	background-repeat: no-repeat;
	background-size: contain;
	height: 26px;
	width: 26px;
}
.notification-bell.bell-ringing {
	background-image: url('@/assets/notification-bell-ringing.svg');
}
.notification-count-container {
	position: absolute;
    top: -1px;
    left: 0;
	margin-left: 12px;
	border-radius: 12px;
    background: $primary-600;
    display: flex;
    justify-content: center;
    align-items: center;
	padding: 2px 7px;
}
.notification-count {
	color: #FFF;
    font-size: 11px;
}

@media only screen and (max-width: 1305px) {
	.notifications {
		margin-right: 7px;
	}
}

@media only screen and (max-width: $large-glass) {
    .notifications {
		margin-right: 15px;
	}
	.notification-count-container {
		top: -8px
	}
}


</style>
