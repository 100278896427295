import axios from 'axios'
import qs from 'qs'
import { defineStore, getActivePinia } from 'pinia'
import { jwtDecode } from 'jwt-decode'
import { globals } from '@/main.js'

export const baseURL = import.meta.env.VITE_APIGEE_BASE_URL // URL from .env file
export const messagingURL = import.meta.env.VITE_APIGEE_MESSAGING_URL // URL from .env file
const userActivityEvent = new Event('user-activity')

// Initialize axios with baseURL
const $axios = axios.create({
	baseURL
})

// Basic API wrapper returns either the data from endpoint or the error
export const API = async (props, testUrl, key = null) => {
	if (testUrl) {
		return APIwrapper(props, testUrl + '/api/v1', key)
	} else {
		return APIwrapper(props, baseURL + '/member/myblueprint/v1')
	}
}

export const AbcbsAPI = async (props, testUrl) => {
	return APIwrapper(props, baseURL + '/abcbs/v1')
}

export const MessagingAPI = async (props) => {
	return APIwrapper(props, messagingURL)
}

const APIwrapper = async (props, apiURL, key = null) => {
	window.dispatchEvent(userActivityEvent)
	if (sessionStorage.getItem('access_token') && !isTokenValid()) {
		await refreshAuthToken()
	}
	const { url, method, headers, body, customError } = props
	const bearerToken = sessionStorage.getItem('access_token')
	const defaultHeaders = {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		Authorization: `Bearer ${bearerToken}`
	}

	// Added Logrocket session to headers
	if (import.meta.env.VITE_LOGROCKET_PATH) {
		let recordingURL = null
		LogRocket.getSessionURL(function (sessionURL) {
			if (sessionURL) recordingURL = sessionURL
		})

		if (recordingURL) {
			defaultHeaders['X-LogRocket-URL'] = recordingURL
		}
	}

	// if using mock api pass header
	if (apiURL.includes('mock.pstmn.io')) {
		defaultHeaders['x-api-key'] = key
	}

	const defaultPayload = {}
	const finalHeader = typeof headers !== 'undefined' ? headers : defaultHeaders

	const finalUrl = url.includes('https') ? url : `${apiURL}/${url}`

	try {
		const { data } = await $axios({
			method,
			url: finalUrl,
			headers: finalHeader,
			data: body || defaultPayload
		})
		return data
	} catch (error) {
		// display generic error modal if no specific msg is received
		if (!error.response?.data?.validationErrors && !customError) {
			globals.$Error(true)
		}

		if (customError) {
			return customError
		} else {
			console.error(error)
			throw error
		}
	}
}

export const getOAuthToken = async (code) => {
	let data = qs.stringify({
		client_id: import.meta.env.VITE_CLIENT_KEY,
		code: code,
		code_verifier: sessionStorage.getItem('code_verifier'),
		grant_type: 'authorization_code',
		redirect_uri: `${window.location.origin}/auth-success`
	})

	let config = {
		method: 'post',
		url: import.meta.env.VITE_OIDC_TOKEN,
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		},
		data: data
	}

	await axios.request(config).then((res) => {
		sessionStorage.setItem('access_token', res.data.access_token)
		sessionStorage.setItem('refresh_token', res.data.refresh_token)
		const decode = jwtDecode(res.data.access_token)
		sessionStorage.setItem('logged_in_user', decode.sub)
		return
	})
}

export const refreshAuthToken = async () => {
	let data = qs.stringify({
		client_id: import.meta.env.VITE_CLIENT_KEY,
		grant_type: 'refresh_token',
		refresh_token: sessionStorage.getItem('refresh_token')
	})

	let config = {
		method: 'post',
		url: import.meta.env.VITE_OIDC_TOKEN,
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		},
		data: data
	}

	await axios.request(config).then((res) => {
		sessionStorage.setItem('access_token', res.data.access_token)
	})
}

export function isTokenValid() {
	const token = sessionStorage.getItem('access_token')
	if (!token) return false
	var decoded = jwtDecode(token)
	var expiration = decoded.exp
	var curDate = new Date().getTime()
	return expiration >= curDate / 1000
}

export const logout = () => {
	const deepLink = sessionStorage.getItem('deep_link')
	const deepLinkMessage = sessionStorage.getItem('deepLinkMessage')
	sessionStorage.clear()
	sessionStorage.clear()
	resetAllStores()
	deepLink ? sessionStorage.setItem('deep_link', deepLink) : false
	deepLinkMessage ? sessionStorage.setItem('deepLinkMessage', deepLinkMessage) : false
	// https://apipreprod.arkbluecross.com/oidc/oauth2/logout
}

function resetAllStores() {
	const activepinia = getActivePinia()
	if (activepinia) {
		Object.entries(activepinia.state.value).forEach(([storeName, state]) => {
			const storeDefinition = defineStore(storeName, state)
			const store = storeDefinition(activepinia)
			store.$reset()
		})
	}
}
