<script>
import { useSelectedPlan } from '@/stores/plans/selectedPlan.js'
import { retrieveCoverage } from '@/composables/coverage.comp.js'

export default {
	mounted() {
		this.createUserIdentity().then((res) => {
			// Setup log rocket identity
			if (import.meta.env.VITE_LOGROCKET_ID) {
				window.LogRocket.identify(res.memberId, {
					usernName: res.usernName,
					groupId: res.groupId,
					planType: res.planType,
					lob: res.lob
				})
			}
		})
	},
	methods: {
		async createUserIdentity() {
			const planStore = useSelectedPlan()
			const coverageStore = await retrieveCoverage().then((res) => res)

			return {
				memberId: planStore.memberId,
				groupId: coverageStore.getGroupId(planStore.memberId),
				usernName: planStore.username,
				planType: planStore.lobDisplayName,
				lob: planStore.tier1
			}
		}
	}
}
</script>
